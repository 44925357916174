import {
  BrowserTracing,
  handleErrorWithSentry,
  Replay,
} from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";
import { version } from "$app/environment";
import { getEnv } from "$lib/utils";

const environment = getEnv();

Sentry.init({
  environment,
  release: version,
  dsn: "https://1c7c9985b43d7f97b8d9fdf9444e94a8@o4504119647207424.ingest.sentry.io/4505742781382656",
  tracesSampleRate: 0.5,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        "login.orion.febrafar.com.br",
        "login.trade.febrafar.com.br",
        "login.pai.febrafar.com.br",
        "login.cadastro.febrafar.com.br",
        ,
      ],
    }),
    new Replay({ maskAllText: false, blockAllMedia: false }),
  ],
  allowUrls: [
    /https:\/\/(www\.)?login\.(orion|cadastro|pai|trade)\.febrafar\.com\.br/,
  ],
  ignoreErrors: [
    // orion-app
    /AbortError:/i,
    /NotSupportedError:/i,
    /Failed to fetch/i,
    /An error occurred during playback/i,
    /null is not an object/i,
    /Unknown player. Probably unloaded/i,
    /Permission denied to access property/i,
    /Seu usuário não tem acesso ao produto/i,
    /Cannot create property 'status' on string/i,
    /Não foi possível consultar o usuário logado atualmente/i,
    // auths
    /Usuário com este email não encontrado/i,
    /Convite não encontrado na base de dados/i,
    /error loading dynamically imported module/i,
    /Not found:/i,
    /Não foi possível autenticar o usuário/i,
    /Ocorreu um erro de rede/i,
    /Houve um problema desconhecido ao consultar os produtos/i,
    /Non-Error promise rejection captured with keys/i,
    // auth.js
    /Too many retried requests/i,
  ],
  beforeSend(event, hint) {
    const error = hint.originalException || hint.syntheticException;

    let mensagemErro = null;

    if (typeof error === "string") {
      mensagemErro = error;
    } else if (error != null && typeof error?.message === "string") {
      mensagemErro = error?.message;
    }

    if (mensagemErro == null) {
      return event;
    }

    const blacklistErrosComuns = [
      "failed to fetch dynamically imported module",
      "an error occurred during playback",
      "seu usuário não tem acesso ao produto",
      "usuário não encontrado",
      "can't access dead object",
      "auth/invalid-custom-token",
      "unknown player. probably unloaded",
      "installations/app-offline",
      "network error",
      "cannot create property 'status' on string",
      "auth/wrong-password",
      "cannot read properties of null (reading 'postmessage')",
    ];

    if (
      blacklistErrosComuns.some((erroComum) =>
        mensagemErro.toLowerCase().includes(erroComum)
      )
    ) {
      return null;
    }

    return event;
  },
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
